import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../utils/api-objects/Exam"
import { getAllExamsByType } from "../../../utils/apis/emr-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../utils/enums/BucketFolder"
import { EventType } from "../../../utils/enums/EventType"
import BackButton from "../components/BackButton"
import LoadingSpinner from "../components/LoadingSpinner"
import NavBar from "../components/NavBar"
import { NavBarElement } from "../components/NavBarElement"
import { PdfCard, PdfType } from "../components/PdfCard"

const ResultsAndProgresses = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email

    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        if (currentItem === NavBarElement.Diagnostica) {
            setCurrentItem(NavBarElement.Menu)
        } else {
            setCurrentItem(NavBarElement.Diagnostica)
        }
    }

    const [report, setReport] = useState<Exam>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!userId || !idToken) {
            return
        }
        getAllExamsByType(
            userId,
            idToken,
            BucketFolder.CrossResults,
            (exams) => {
                setReport(exams[0])
                setIsLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLoading(false)
            }
        );
        if (!email) return
        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "results_and_progresses_page",
        )
    }, []);

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Diagnostica"
                                onClick={() => navigate("/diagnostica")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Risultati e progressi
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                            In questa cartella trovi il risultato aggregato del test del DNA e delle analisi degli Aminoacidi.
                        </div>
                        {
                            !isLoading &&
                            (report && <PdfCard exam={report} pdfType={PdfType.CrossResults} />)
                        }
                        <div className="p-[10px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default ResultsAndProgresses

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import EmailInputElement from "../../../components/input/v3/EmailInputElement"
import { SortingItem } from "../../../utils/api-objects/SortingItem"
import { assignMedicalUserToPatient, getMedicalUserByRole } from "../../../utils/apis/medicaluser-service"
import { assignSortingItems, createProduct, getRedeemableSortingItems, redeemSortingItems } from "../../../utils/apis/order-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useExpert } from "../../../utils/context/ExpertContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { CreateProductChannel } from "../../../utils/enums/CreateProductChannel"
import { ExpertRole } from "../../../utils/enums/Experts"
import { ProductType } from "../../../utils/enums/ProductType"
import { createExpertFromCalendlyExpert, getRandomNutritionist, handleShopifyProductName } from "../../../utils/Functions"
import LoadingSpinner from "../components/LoadingSpinner"
import { WarningDialog } from "../components/WarningDialog"

const Sorting = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const { experts } = useExpert()
    const nutritionist = experts.nutritionist

    const [sortingItems, setSortingItems] = useState<SortingItem[]>()
    const [selectedItems, setSelectedItems] = useState<{ [key: number]: { isToggled: boolean; giftEmail?: string } }>({});
    const [isFullLoading, setIsFullLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const isConfirmEnabled = sortingItems
        ? Object.values(selectedItems).some(item => item.isToggled || item.giftEmail !== "")
        : false;

    useEffect(() => {
        const fetchSortingItems = async () => {
            if (!email || !idToken) return;

            setIsFullLoading(true);
            const result = await getRedeemableSortingItems(email, idToken);

            if (result.length > 0) {
                const items = result;
                setSortingItems(items);
                // Initialize selectedItems with a default structure for each item
                const initialSelectedItems: { [key: number]: { isToggled: boolean; giftEmail?: string } } = items.reduce((acc, item, index) => {
                    acc[index] = { isToggled: false, giftEmail: "" };
                    return acc;
                }, {} as { [key: number]: { isToggled: boolean; giftEmail?: string } });
                setSelectedItems(initialSelectedItems);
            } else {
                navigate("/redeem")
            }
            setIsFullLoading(false);
        };

        fetchSortingItems();
    }, [email, idToken]);


    const handleToggle = (index: number, isToggled: boolean) => {
        setSelectedItems((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                isToggled: isToggled,
                giftEmail: isToggled ? undefined : prev[index]?.giftEmail  // Clear giftEmail if toggled
            }
        }))
    }

    const handleGiftEmailChange = (index: number, email: string) => {
        setSelectedItems((prev) => ({
            ...prev,
            [index]: {
                ...(prev[index] || {}),  // Initialize as empty object if undefined
                giftEmail: email
            }
        }))
    }

    const handleConfirm = async () => {
        if (!userId || !idToken || !email) return
        const selectedItemsData = sortingItems?.map((item, index) => ({
            item,
            ...selectedItems[index]
        }))

        setIsLoading(true)

        const itemsToRedeem = selectedItemsData?.filter(data => data.isToggled && !data.giftEmail) || [];
        const itemsToAssign = selectedItemsData?.filter(data => data.giftEmail) || [];

        // Prepare promises for items to assign
        const assignPromises = itemsToAssign.map((selectedItem) => {
            const gifter = selectedItem.giftEmail;
            const id = selectedItem.item.id;

            return new Promise((resolve, reject) => {
                if (!gifter) return
                assignSortingItems(
                    id,
                    gifter,
                    idToken,
                    (success) => {
                        resolve(success);
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
            });
        });

        // Prepare promises for items to redeem
        const redeemPromises = itemsToRedeem.map((selectedItem) => {
            return new Promise((resolve, reject) => {
                redeemSortingItems(
                    userId,
                    selectedItem.item.id,
                    email,
                    idToken,
                    (success) => {
                        const buyer = success.buyer;
                        let type = success.product_type;
                        if (type === ProductType.SubHealthDna) {
                            type = ProductType.SubHealth;
                        } else if (type === ProductType.SubNutritionDna) {
                            type = ProductType.SubNutrition;
                        }
                        const redeemer = email
                        const shipping_address = success.product_data.shipping_address

                        createProduct(
                            userId,
                            idToken,
                            type,
                            (success) => {
                                if ((type === ProductType.Nutritionist || type === ProductType.SubHealth || type === ProductType.SubNutrition) && !nutritionist) {
                                    assignNutri();
                                }
                                resolve(success);
                            },
                            (error) => {
                                console.log(error);
                                reject(error);
                            },
                            {
                                shopify_product: type,
                                channel: CreateProductChannel.ShopifyPurchase,
                                code: null,
                                buyer: buyer,
                                redeemer: redeemer,
                                shipping_address: shipping_address,
                            }
                        );
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
            });
        });

        try {
            await Promise.all([...assignPromises, ...redeemPromises]);
            navigate("/check-purchases");
        } catch (error) {
            console.error("Error processing items:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const assignNutri = () => {
        callGetMedicalUsersList()
    }

    const callGetMedicalUsersList = () => {
        if (!idToken || !userId) return
        getMedicalUserByRole(
            idToken,
            ExpertRole.Nutritionist,
            (nutritionists) => {
                const nutritionist = getRandomNutritionist(nutritionists)
                const newExpert = createExpertFromCalendlyExpert(nutritionist)
                assignNutritionist(newExpert.id!)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    const assignNutritionist = (nutriId: string) => {
        if (!userId || !idToken) return
        assignMedicalUserToPatient(
            nutriId,
            userId,
            idToken,
            () => { navigate("/check-purchases") },
            (error) => {
                console.log(error)
                navigate("/check-purchases")
            },
        )
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex flex-col items-center justify-end md:justify-center">
                <div className="w-[90%] h-[90%] flex flex-col items-center md:h-fit">
                    <div className="h-[35px] mb-[10px]">
                        <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                    </div>
                    {
                        isFullLoading ? <div className="mt-[250px]"><LoadingSpinner /></div> :
                            <div className="flex flex-col items-center">
                                <div className="font-normal text-[16px] leading-[24px] text-center mt-[10px]">
                                    Questi sono gli acquisti associati al tuo indirizzo e-mail. Sono per te o per qualcun altro?
                                </div>
                                {
                                    sortingItems &&
                                    <div className="w-full my-[30px] md:max-h-[70vh] md:overflow-y-auto scrollbar-hide md:p-[10px]">
                                        {
                                            sortingItems.map((item, index) => (
                                                <SortingItemCard
                                                    key={index}
                                                    item={item}
                                                    index={index}
                                                    isToggled={selectedItems[index]?.isToggled || false}
                                                    onToggle={handleToggle}
                                                    giftEmail={selectedItems[index]?.giftEmail!}
                                                    onGiftEmailChange={handleGiftEmailChange}
                                                    dnaTestSelected={
                                                        Object.values(selectedItems).some((selectedItem, idx) =>
                                                            selectedItem.isToggled &&
                                                            (sortingItems[idx].product_type === ProductType.DnaTest || sortingItems[idx].product_type === ProductType.SubHealthDna || sortingItems[idx].product_type === ProductType.SubNutritionDna)
                                                        )
                                                    }
                                                    subSelected={
                                                        Object.values(selectedItems).some((selectedItem, idx) =>
                                                            selectedItem.isToggled &&
                                                            (sortingItems[idx].product_type === ProductType.SubHealth || sortingItems[idx].product_type === ProductType.SubHealthDna || sortingItems[idx].product_type === ProductType.SubNutrition || sortingItems[idx].product_type === ProductType.SubNutritionDna)
                                                        )
                                                    }
                                                />
                                            ))
                                        }
                                    </div>
                                }
                                <div className="font-normal text-[12px] leading-[16px] text-center">
                                    Puoi assegnare qualsiasi acquisto in un secondo momento dall'area Acquisti e Metodi di pagamento.
                                </div>
                                <div className="w-full h-[50px] mt-[30px] flex-shrink-0 md:w-[328px]">
                                    <PrimaryButton
                                        text="Conferma"
                                        pointer={isConfirmEnabled}
                                        disabled={!isConfirmEnabled}
                                        onClick={handleConfirm}
                                        isLoading={isLoading}
                                    />
                                </div>
                                <div className="p-[10px]" />
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Sorting

interface Props {
    item: SortingItem,
    index: number,
    isToggled: boolean,
    onToggle: (index: number, isToggled: boolean) => void,
    giftEmail: string,
    onGiftEmailChange: (index: number, email: string) => void,
    dnaTestSelected: boolean,
    subSelected: boolean,
}

const SortingItemCard: React.FC<Props> = ({
    item,
    index,
    isToggled,
    onToggle,
    giftEmail,
    onGiftEmailChange,
    dnaTestSelected,
    subSelected,
}) => {
    const { productsData } = useProducts()
    const dnaTest = productsData.dnaTest
    const [showPopup, setShowPopup] = useState(false)
    const [warningText, setWarningText] = useState("")

    const inputValue = giftEmail !== undefined ? giftEmail : item.redeemable_by || ""

    const isSub = item.product_type === ProductType.SubHealth || item.product_type === ProductType.SubHealthDna || item.product_type === ProductType.SubNutrition || item.product_type === ProductType.SubNutritionDna
    const isDnaSub =
        // item.product_type === ProductType.SubHealthDna || 
        item.product_type === ProductType.SubNutritionDna
    const isNoDnaSub = item.product_type === ProductType.SubHealth || item.product_type === ProductType.SubNutrition
    const isDnaProduct = isDnaSub || item.product_type === ProductType.DnaTest

    const shoudlShowWarning = (dnaTestSelected && isDnaProduct) ||
        // (!dnaTestSelected && isNoDnaSub && dnaTest === null) ||
        (subSelected && isSub)

    const handleToggle = () => {
        if (shoudlShowWarning) {
            setShowPopup(true)
            setWarningText("Non puoi associare questo acquisto al tuo account. Inserisci una mail a cui regalare questo prodotto.")
            return
        } else {
            onToggle(index, !isToggled)
        }
    };

    const handleGiftEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        onGiftEmailChange(index, e.target.value)
    }

    const closePopup = () => {
        setShowPopup(false)
    }

    // ********************************************************************************

    return (
        <div className="w-full p-[20px] rounded-[15px] shadow-platform mb-[20px]">
            <div className="w-full flex items-center justify-between">
                <div className="font-bold text-[14px] leading-[26px]">
                    {handleShopifyProductName(item.product_type)}
                </div>
                <div className="flex items-center gap-2">
                    <div className={`font-semibold text-[10px] leading-[26px] ${(!isToggled) ? "text-holifya-blue" : ""}`}>
                        {(isToggled) ? "Assegnato a me" : "Assegna a me"}
                    </div>
                    <div
                        className={`w-[40px] h-[20px] flex items-center rounded-[25px] cursor-pointer ${(isToggled) ? "bg-[#D6E3F7]" : "bg-[#D9D9D9]"}`}
                        onClick={handleToggle}
                    >
                        <div className={`w-[20px] h-[20px] rounded-[10px] transform duration-300 ease-in-out ${(isToggled) ? "translate-x-[20px] bg-[#3B82F7]" : "bg-[#B8B8B8]"}`} />
                    </div>
                </div>
            </div>
            {
                !isToggled &&
                <div className="mt-[15px]">
                    <EmailInputElement
                        name={`${index}`}
                        label="Puoi assegnarlo a un altro indirizzo e-mail"
                        inputValue={inputValue}
                        onChange={handleGiftEmailInput}
                        placeholder="Indirizzo e-mail"
                    />
                </div>
            }
            {
                showPopup && (
                    <WarningDialog
                        text={warningText}
                        onClose={closePopup}
                    />
                )}
        </div>
    )
}

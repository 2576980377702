export enum ProductType {
    DnaTest = "dna-test",
    BloodTest = "blood-test",
    ExpertConsult = "expert-consult",
    Nutritionist = "nutritionist-consult",
    SubHealth = "sub-health",
    SubHealthDna = "sub-health-dna",
    SubHealthUpsell = "sub-health-upsell",
    SubNutrition = "sub-nutrition",
    SubNutritionDna = "sub-nutrition-dna"
}
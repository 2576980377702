import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { logoutAPI } from "../../../utils/apis/auth-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { ENVIRONMENT, NEW_PLATFORM_DIAGNOSTICA_LOGO, NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS, NEW_PLATFORM_EQUIPE_LOGO, NEW_PLATFORM_EQUIPE_LOGO_FOCUS, NEW_PLATFORM_HEALTH_PLAN_LOGO, NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS, NEW_PLATFORM_HOME_LOGO, NEW_PLATFORM_HOME_LOGO_FOCUS, NEW_PLATFORM_MENU_LOGO, NEW_PLATFORM_MENU_LOGO_FOCUS, NEW_PLATFORM_MENU_LOGO_X } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useTutorial } from "../../../utils/context/TutorialContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { Environment } from "../../../utils/enums/Environment"
import { EventType } from "../../../utils/enums/EventType"
import { TutorialType } from "../../../utils/enums/TutorialType"
import { NavBarElement } from "./NavBarElement"

interface Props {
  currentItem: NavBarElement,
  onMenuClick: () => void,
  disabled?: boolean,
}

const NavBar: React.FC<Props> = ({
  currentItem,
  onMenuClick,
  disabled = false,
}) => {
  const navigate = useNavigate()
  const { auth, logout } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const username = userData.name
  const familyName = userData.familyName
  const email = userData.email
  const { tutorial } = useTutorial()
  const tutorialMenu = tutorial.tutorial
  const { productsData } = useProducts()
  const membership = productsData.membership
  const dnaTest = productsData.dnaTest

  const premiumRequirements = membership && ENVIRONMENT === Environment.DEVELOP
  const dnaTestUser = dnaTest && !membership && ENVIRONMENT === Environment.DEVELOP

  const userDataAvailable = username !== null && familyName !== null && email !== null

  const onLogoutClick = () => {
    userDataAvailable && logoutAPI(
      email,
      () => {
        logout()
      },
      (error) => {
        console.log(error)
      }
    )
    email && idToken && saveEvent(
      email,
      idToken,
      "logout_button_clicked",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "menu"
    )
  }

  const onHowItWorksClick = () => {
    if (!tutorialMenu) return

    switch (tutorialMenu.tutorial_type) {
      case TutorialType.DNA_TEST:
        return navigate(`/how-it-works-dna`)
      case TutorialType.CONSULT:
        return
      case TutorialType.SUB_HEALTH:
        return navigate(`/how-it-works-sub-health`)
      case TutorialType.SUB_NUTRITION:
        return navigate(`/how-it-works-sub-nutrition`)
    }
  }

  const handleSourcePage = () => {
    switch (currentItem) {
      case NavBarElement.Home:
        return "home_page"
      case NavBarElement.HealthPlan:
        return "health_plan_page"
      case NavBarElement.Diagnostica:
        return "diagnostica_page"
      case NavBarElement.Equipe:
        return "equipe_page"
      case NavBarElement.Menu:
        return "menu_open"
      case NavBarElement.MenuElement:
        return "menu_element_page"
    }
  }

  const onHomeClick = () => {
    navigate("/home")
    if (currentItem !== NavBarElement.Home && email && idToken) {
      saveEvent(
        email,
        idToken,
        EventType.HomeNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    }
  }

  const onHealthPlanClick = () => {
    navigate("/health-plan")
    if (currentItem !== NavBarElement.HealthPlan && email && idToken) {
      saveEvent(
        email,
        idToken,
        EventType.HealthPlanNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    }
  }

  const onDiagnosticaClick = () => {
    navigate("/diagnostica")
    if (currentItem !== NavBarElement.Diagnostica && email && idToken) {
      saveEvent(
        email,
        idToken,
        EventType.DiagnosticaNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    }
  }

  const onEquipeClick = () => {
    navigate("/equipe")
    if (currentItem !== NavBarElement.Equipe && email && idToken) {
      saveEvent(
        email,
        idToken,
        EventType.EquipeNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    }
  }

  const onMenuItemClick = () => {
    onMenuClick()
    if (!email || !idToken) return
    if (currentItem !== NavBarElement.Menu) {
      saveEvent(
        email,
        idToken,
        EventType.MenuOpenNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    } else {
      saveEvent(
        email,
        idToken,
        EventType.MenuCloseNavbarElementClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        handleSourcePage(),
      )
    }
  }

  // Preload images when the component mounts
  useEffect(() => {
    const images = [
      NEW_PLATFORM_HOME_LOGO,
      NEW_PLATFORM_HOME_LOGO_FOCUS,
      NEW_PLATFORM_HEALTH_PLAN_LOGO,
      NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS,
      NEW_PLATFORM_DIAGNOSTICA_LOGO,
      NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS,
      NEW_PLATFORM_EQUIPE_LOGO,
      NEW_PLATFORM_EQUIPE_LOGO_FOCUS,
      NEW_PLATFORM_MENU_LOGO,
      NEW_PLATFORM_MENU_LOGO_FOCUS,
    ]

    images.forEach((src) => {
      const img = new Image()
      img.src = src
    })
  }, [])

  // ********************************************************************************

  return (
    <div className={`w-full h-[90px] fixed bottom-0 ${premiumRequirements ? "bg-holifya-green" : (dnaTestUser ? "bg-holifya-red" : "bg-holifya-navy-blue")} flex items-center justify-center ${disabled && "opacity-50 pointer-events-none"} md:w-[100px] md:h-full md:fixed md:left-0 md:bottom-auto md:flex-col`}>
      <div className="w-[90%] h-full flex items-center justify-between md:flex-col md:h-[400px]">
        <NavBarItem
          image={currentItem === NavBarElement.Home ? NEW_PLATFORM_HOME_LOGO_FOCUS : NEW_PLATFORM_HOME_LOGO}
          text="Home"
          onClick={onHomeClick}
        />
        <NavBarItem
          image={currentItem === NavBarElement.HealthPlan ? NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS : NEW_PLATFORM_HEALTH_PLAN_LOGO}
          text="Health Plan"
          onClick={onHealthPlanClick}
        />
        <NavBarItem
          image={currentItem === NavBarElement.Diagnostica ? NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS : NEW_PLATFORM_DIAGNOSTICA_LOGO}
          text="Diagnostica"
          onClick={onDiagnosticaClick}
        />
        <NavBarItem
          image={currentItem === NavBarElement.Equipe ? NEW_PLATFORM_EQUIPE_LOGO_FOCUS : NEW_PLATFORM_EQUIPE_LOGO}
          text="Equipe"
          onClick={onEquipeClick}
        />
        <NavBarItem
          image={currentItem === NavBarElement.Menu ? NEW_PLATFORM_MENU_LOGO_X : (currentItem === NavBarElement.MenuElement ? NEW_PLATFORM_MENU_LOGO_FOCUS : NEW_PLATFORM_MENU_LOGO)}
          text="Menu"
          onClick={onMenuItemClick}
        />
      </div>
      {
        currentItem === NavBarElement.Menu &&
        <>
          {/* Background Overlay */}
          <div className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black opacity-30 z-[19] md:left-[100px]" onClick={onMenuClick} />
          <div className="fixed top-0 left-0 w-full h-[calc(100%-90px)] bg-holifya-menu z-[20] flex flex-col items-center justify-center overflow-y-auto scrollbar-hide md:left-[100px] md:top-0 md:w-[393px] md:h-full">
            <div className="w-[90%] h-full p-[20px] flex flex-col md:justify-between">
              {/* Top Section */}
              <div>
                <div className="w-full h-[35px] flex items-center justify-between">
                  <div className="leading-none">
                    <img src="/images/new-platform/logos/holifya-logo.svg" />
                  </div>
                  <div className="cursor-pointer" onClick={onMenuClick}>
                    <img src="/images/new-platform/components/x.svg" />
                  </div>
                </div>
                <div className="mt-[20px] mb-[10px]">
                  <div className="font-bold text-[25px] leading-[33px]">
                    {username} {familyName}
                  </div>
                </div>
                <div className="mb-[20px]">
                  <div className="font-normal text-[14px] leading-[33px] no-underline text-inherit pointer-events-none cursor-default">
                    <span>{email}</span>
                  </div>
                </div>
                <div className="w-[300px] h-[50px]">
                  <PrimaryButton
                    text="Acquista"
                    pointer={true}
                    disabled={false}
                    fontSize={18}
                    onClick={() => navigate("/purchase")}
                  />
                </div>
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Menu
                  </div>
                </div>
                <MenuItem
                  text={`${"\u{1F642}"} Dati personali`}
                  onClick={() => navigate("/menu/personal-data")}
                />
                <MenuItem
                  text={`${"\u{1F4B3}"} Acquisti e metodi di pagamento`}
                  onClick={() => navigate("/menu/payment-methods-and-purchases")}
                />
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Assistenza
                  </div>
                </div>
                <MenuItem
                  text={`${"\u{1F6E0}"} Come funziona`}
                  onClick={onHowItWorksClick}
                />
                <MenuItem
                  text={`${"\u{1F64B}"} Domande frequenti`}
                  onClick={() =>
                    window.open("https://www.holifya.com/pages/faqs", "_blank")
                  }
                />
                <MenuItem text={`${"\u{1F4E7}"} Scrivici`} mailTo="support@holifya.com" />
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Note legali
                  </div>
                </div>
                <MenuItem
                  text={`⚖️ Termini e condizioni`}
                  href="/assets/new-platform/personal-data-treatment.pdf"
                />
                <MenuItem
                  text={`⚖️ Privacy policy`}
                  href="/assets/new-platform/privacy-policy.pdf"
                />
              </div>

              {/* Bottom Section */}
              <div>
                <div className="w-full h-[1.5px] bg-black" />
                <MenuItem text={`⬅️ Esci`} onClick={onLogoutClick} />
                <div className="height_20" />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default NavBar

interface ItemProps {
  image: string,
  text: string,
  onClick: () => void,
}

const NavBarItem: React.FC<ItemProps> = ({
  image,
  text,
  onClick,
}) => {
  return (
    <div className="h-[50%] flex flex-col items-center justify-between cursor-pointer md:h-fit" onClick={onClick}>
      <div className="w-[20px] h-[20px] flex justify-center md:mb-[10px]">
        <img src={image} alt={text} />
      </div>
      <div className="font-bold text-[10px] leading-[15px] text-white">{text}</div>
    </div>
  )
}

interface MenuItemProps {
  text: string,
  onClick?: () => void,
  href?: string,
  mailTo?: string,
}

const MenuItem: React.FC<MenuItemProps> = ({
  text,
  onClick,
  href,
  mailTo,
}) => {
  const isLink = !!href;
  const isMail = !!mailTo;

  const Component = isLink || isMail ? 'a' : 'div';

  return (
    <Component
      href={isLink ? href : (isMail ? `mailto:${mailTo}` : undefined)}
      onClick={!isLink && !isMail ? onClick : undefined}
      className="my-[30px] font-bold text-[16px] leading-[20px] no-underline cursor-pointer"
      style={{ color: 'black', display: 'block', textDecoration: 'none' }}
      target={isLink || isMail ? "_blank" : undefined} // Open PDFs in a new tab
      rel={isLink ? "noopener noreferrer" : undefined}
    >
      {text}
    </Component>
  )
}

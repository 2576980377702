/**
 * Props interface for EmailInputElement.
 * 
 * @property {string} name - the name of the input field
 * @property {string} label - The label text for the input field.
 * @property {string} placeholder - The placeholder text for the input field.
 * @property {string} errorText - The error message to display.
 * @property {string} inputValue - The current value of the input field.
 * @property {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - Callback function to handle input changes.
 * @property {() => void} [onBlur] - Optional callback function to handle input blur events.
 */
interface Props {
    name?: string,
    label: string,
    placeholder: string,
    errorText: string,
    inputValue: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: () => void,
}

/**
 * EmailInputElement Component
 * 
 * A reusable email input component with label, placeholder, error message, and change/blur handlers.
 * 
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered email input element.
 */
const EmailInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    errorText,
    inputValue,
    onChange,
    onBlur,
}) => {
    return (
        <div className="w-full flex flex-col my-[20px]">
            <label
                className="font-semibold text-[12px] uppercase"
                htmlFor={`email-input-${label}`}
            >
                {label}
            </label>
            <input
                id={`email-input-${label}`}
                name={name}
                className="h-[40px] border-b border-solid border-black outline-none pl-[5px] lowercase font-normal text-[16px] rounded-none"
                placeholder={placeholder}
                value={inputValue}
                type="email"
                onChange={onChange}
                autoComplete="email"
                autoCapitalize="none"
                onBlur={onBlur}
            />
            {errorText && <div className="mt-[10px] font-semibold leading-[17px] text-[12px] text-holifya-red" aria-live="polite">
                {errorText}
            </div>}
        </div>
    )
}

export default EmailInputElement

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { saveEvent } from "../../../../utils/apis/user-service"
import { NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import CloseButton from "../../components/CloseButton"

const HealthAssessmentHomePage = () => {

    const navigate = useNavigate()

    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email

    const onProceedClick = () => {
        navigate("/health-assessment-survey")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            "go_to_health_assessment_button_click",
            (success) => { console.log(success)},
            (error) => { console.log(error)},
            "health_assessment_home_page"
        )
    }

    const onBackClick = () => {
        navigate("/home")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            "back_to_home_page_button_click",
            (success) => { console.log(success)},
            (error) => { console.log(error)},
            "health_assessment_home_page"
        )
    }

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success)},
            (error) => { console.log(error)},
            "health_assessment_home_page"
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-[100px] flex items-center justify-center border-b border-solid border-black md:h-[200px]">
                <div className="w-[90%] h-[90%] flex items-center justify-between md:w-[80%]">
                    <div className="flex items-center">
                        <img className="mr-[10px]" src="/images/new-platform/logos/holifya-logo-no-text.svg" alt="holifya-logo" />
                        <div className="font-bold text-[12px] leading-[17px]">Questionario | Health Assessment</div>
                    </div>
                    <CloseButton onClick={() => navigate("/home")} />
                </div>
            </div>
            <div className="w-full h-fit overflow-y-auto scrollbar-hide md:w-[80%]">
                <div className="w-full h-full p-[20px] font-normal text-[16px] leading-[26px] md:p-0 md:py-[20px]">
                    Rispondi ora al questionario di 📝 <strong>Health Assessment</strong> per fornire tutte le informazioni necessarie per personalizzare al massimo le indicazioni che puoi ricevere nel tuo Percorso Health, se ne hai acquistato uno, e dalla Medical Equipe durante i consulti con Medico e Nutrizionista.
                    <br/>
                    <br/>
                    Dovrai rispondere a questo primo questionario completo una sola volta. Potrai poi rispondere al <strong>questionario di check-up</strong> per aggiornare e tenere traccia del tuo stato di salute e delle tue abitudini durante tutto il tuo percorso con Holifya.
                    <br/>
                    <br/>
                    Puoi modificare le singole risposte in qualsiasi momento accedendo a Health Assessment da <strong>Diagnostica</strong> ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_DIAGNOSTICA_LOGO_BLACK}/></span> ).
                </div>
            </div>
            <div className="w-full h-[120px] flex items-center justify-center md:w-[750px]">
                <div className="w-[90%] h-[100px] flex flex-col justify-between md:flex-row">
                    <div className="w-full h-[50px] md:w-[362px]">
                        <PrimaryButton
                            text={"Inizia"}
                            pointer={true}
                            disabled={false}
                            fontSize={16}
                            onClick={onProceedClick}
                        />
                    </div>
                    <div className="w-full h-[40px] md:w-[362px]">
                        <WhiteButton
                            text="Chiudi"
                            onClick={onBackClick}
                            fontSize={16}
                            uppercase={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthAssessmentHomePage

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { saveEvent } from "../../../../utils/apis/user-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { EventType } from "../../../../utils/enums/EventType";
import CloseButton from "../../components/CloseButton";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";

const BloodPacking = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()
  const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
  const onMenuClick = () => {
    setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica);
  };

  const [showEnvelopeDialog, setShowEnvelopeDialog] = useState(false)
  const [showLetteraDiVetturaDialog, setShowLetteraDiVetturaDialog] = useState(false)

  const onConfirmClick = () => {
    navigate("/diagnostica/tests-and-reports/blood-test/request-pickup")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.BloodPackingButtonClicked,
      () => { },
      () => { },
      "blood_packing_page",
    )
  }

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "blood_packing_page",
    )
  })

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className={`w-full h-full md:flex md:flex-row-reverse`}>
        <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide ${(showEnvelopeDialog || showLetteraDiVetturaDialog) && "opacity-50 pointer-events-none"} md:w-[calc(100%-100px)] md:h-full`}>
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="flex justify-end my-[10px]">
              <CloseButton
                onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
              />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Impacchetta
            </div>
            <ul className="list-disc ml-[20px]">
              <li>
                Inserisci il sacchetto di plastica nella <span className="font-bold underline text-holifya-blue" onClick={() => setShowEnvelopeDialog(true)}>busta per la spedizione</span> e sigilla.
              </li>
              <li>
                La spedizione deve essere effettuata lo stesso giorno o il giorno seguente.
              </li>
              <li>
                Deposita la busta contenente il test al <strong>TNT Point più vicino</strong> accertandoti che la <span className="font-bold underline text-holifya-blue" onClick={() => setShowLetteraDiVetturaDialog(true)}>lettera di vettura</span> <strong>sia apposta</strong> all'esterno della busta oppure richiedendo il <strong>ritiro</strong> direttamente <strong>a casa tua</strong>.
              </li>
            </ul>
            <div className="w-full rounded-[15px] bg-[#CBEBB7] mt-[20px]">
              <div className="w-full p-[20px]">
                <div className="font-bold text-[20px] leading-[26px] mb-[10px]">😊 Attenzione</div>
                <div>Il costo della spedizione è incluso nel tuo acquisto. Non dovrai pagare nulla né richiedendo il ritiro da casa tua né consegnando la busta in un TNT Point.</div>
              </div>
            </div>
            <div className="font-normal text-[16px] leading-[26px] mt-[20px]">
              <strong>Nota:</strong> Anche se i campioni di sangue essiccati sono molto stabili, una spedizione rapida riduce il rischio di errori durante l'analisi.
            </div>
            <div className="w-full h-[100px] flex flex-col justify-between mt-[20px] flex-shrink-0">
              <div className="w-full h-[50px]">
                <PrimaryButton
                  text="Richiedi il ritiro"
                  pointer={true}
                  disabled={false}
                  onClick={onConfirmClick}
                />
              </div>
              <div className="w-full h-[40px]">
                <WhiteButton
                  text="Indietro"
                  onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
                  uppercase={false}
                />
              </div>
            </div>
            <div className="pt-[10px]" />
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
      {
        showEnvelopeDialog && <EnvelopeDialog onCloseClick={() => setShowEnvelopeDialog(false)} />
      }
      {
        showLetteraDiVetturaDialog && <LetteraDiVetturaDialog onCloseClick={() => setShowLetteraDiVetturaDialog(false)} />
      }
    </div>
  )
}

export default BloodPacking

interface Props3 {
  onCloseClick: () => void,
}

const EnvelopeDialog: React.FC<Props3> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Busta per la spedizione
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Busta per la spedizione
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-envelope.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

const LetteraDiVetturaDialog: React.FC<Props3> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Lettera di vettura
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Lettera di vettura
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3] flex items-center justify-center">
          <img src="/images/new-platform/components/blood-test-lettera-di-vettura.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

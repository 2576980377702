export enum EventType {
    LoginSuccess = "login_success",
    UpsellCardClicked = "upsell_card_clicked",
    UpsellPopupClicked = "upsell_popup_clicked",
    DismissUpsellPopupClicked = "dismiss_upsell_popup_clicked",
    ScrollToUpsellCardButtonClicked = "scroll_to_upsell_card_button_clicked",
    HealthAssessmentControlPanelItemClicked = "health_assessment_control_panel_item_clicked",
    DnaTestControlPanelItemClicked = "dna_test_control_panel_item_clicked",
    BloodTestControlPanelItemClicked = "blood_test_control_panel_item_clicked",
    ExpertConsultControlPanelItemClicked = "expert_consult_control_panel_item_clicked",
    NutritionistConsultControlPanelItemClicked = "nutritionist_consult_control_panel_item_clicked",
    HomeNavbarElementClicked = "home_navbar_element_clicked",
    HealthPlanNavbarElementClicked = "health_plan_navbar_element_clicked",
    DiagnosticaNavbarElementClicked = "diagnostica_navbar_element_clicked",
    EquipeNavbarElementClicked = "equipe_navbar_element_clicked",
    MenuOpenNavbarElementClicked = "menu_open_navbar_element_clicked",
    MenuCloseNavbarElementClicked = "menu_close_navbar_element_clicked",
    MealPlanFolderClicked = "meal_plan_folder_clicked",
    DietFolderClicked = "diet_folder_clicked",
    ShoppingListFolderClicked = "shopping_list_folder_clicked",
    EatingHabitsFolderClicked = "eating_habits_folder_clicked",
    TipsAndRecepesFolderClicked = "tips_and_recepes_folder_clicked",
    SeasonalFruitsAndVegetablesFolderClicked = "seasonal_fruits_and_vegetables_folder_clicked",
    ResultsAndProgressesFolderClicked = "results_and_progresses_folder_clicked",
    TestsAndReportsFolderClicked = "tests_and_reports_folder_clicked",
    DnaTestFolderClicked = "dna_test_folder_clicked",
    BloodTestFolderClicked = "blood_test_folder_clicked",
    HealthAssessmentFolderClicked = "health_assessment_folder_clicked",
    UploadedForYouFolderClicked = "uploaded_for_you_folder_clicked",
    UploadedFromYouFolderClicked = "uploaded_from_you_folder_clicked",
    DnaTestDownloadButtonClicked = "dna_test_download_button_clicked",
    BloodTestDownloadButtonClicked = "blood_test_download_button_clicked",
    CrossResultsDownloadButtonClicked = "cross_results_download_button_clicked",
    HealthPlanDownloadButtonClicked = "health_plan_download_button_clicked",
    DietDownloadButtonClicked = "diet_download_button_clicked",
    EatingHabitsDownloadButtonClicked = "eating_habits_download_button_clicked",
    TipsAndRecepesDownloadButtonClicked = "tips_and_recepes_download_button_clicked",
    ShoppingListDownloadButtonClicked = "shopping_list_download_button_clicked",
    SeasonalFruitsAndVegetablesDownloadButtonClicked = "fruits_and_vegetables_download_button_clicked",
    UploadedForYouDownloadButtonClicked = "uploaded_for_you_download_button_clicked",
    DnaPurchaseButtonClicked = "dna_purchase_button_clicked",
    DnaTestAlreadyReceivedClicked = "dna_received_button_clicked",
    DnaTrackerButtonClicked = "dna_tracker_button_clicked",
    DnaTutorialDoneButtonClicked = "dna_tutorial_done_button_clicked",
    DnaRegisterSwabcodeButtonClicked = "dna_register_swabcode_button_clicked",
    DnaPackingButtonClicked = "dna_packing_button_clicked",
    DnaRequestPickupButtonClicked = "dna_request_pickup_button_clicked",
    BloodTestAlreadyReceivedClicked = "blood_received_button_clicked",
    BloodTrackerButtonClicked = "blood_tracker_button_clicked",
    BloodTutorialForwardButtonClicked = "blood_tutorial_forward_button_clicked",
    BloodTutorialDoneButtonClicked = "blood_tutorial_done_button_clicked",
    BloodRegisterBloodcodeButtonClicked = "blood_register_bloodcode_button_clicked",
    BloodPackingButtonClicked = "blood_packing_button_clicked",
    BloodRequestPickupButtonClicked = "blood_request_pickup_button_clicked",
    PageView = "page_view",
}
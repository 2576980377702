import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { getAppointments, readsConsultsLeft } from "../../../utils/apis/booking-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useConsults } from "../../../utils/context/ConsultsContext"
import { useExpert } from "../../../utils/context/ExpertContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { AppointmentStatus, ConsultType } from "../../../utils/enums/Consults"
import { EventType } from "../../../utils/enums/EventType"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions"
import { Appointment } from "../components/Appointment"
import { Folder } from "../components/Folder"
import FolderBox from "../components/FolderBox"
import NavBar from "../components/NavBar"
import { NavBarElement } from "../components/NavBarElement"
import ConsultCard from "./components/ConsultCard"

const Equipe = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { userData } = useUserData()
  const email = userData.email
  const { consultsData, updateNutritionistConsultsLeft, updateSpecialistConsultsLeft, updateAllAppointments, updateMostRecentFutureAppointment, updateNutritionistFutureConsults, updateSpecialistFutureConsults, updateAllFutureAppointments, updateAllPastAppointments } = useConsults()
  const mostRecentAppointment = consultsData.mostRecentFutureAppointment
  const { experts } = useExpert()
  const nutritionist = experts.nutritionist
  const specialist = experts.specialist
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [currentItem, setCurrentItem] = useState(NavBarElement.Equipe)
  const onMenuClick = () => {
    if (currentItem === NavBarElement.Equipe) {
      setCurrentItem(NavBarElement.Menu)
    } else {
      setCurrentItem(NavBarElement.Equipe)
    }
  }

  const callGetAppointments = () => {
    setIsLoading(true)
    if (!email || !idToken) return
    getAppointments(
      idToken,
      email,
      (appointments) => {
        const allAppointments: Appointment[] = []
        for (const appointment of appointments) {
          allAppointments.push({
            date: appointment.payload.scheduled_event.start_time,
            editUrl: appointment.payload.reschedule_url,
            connectUrl: appointment.payload.scheduled_event.location.join_url,
            cancelUrl: appointment.payload.cancel_url,
            remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
            remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
            remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
            expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? specialist! : nutritionist!,
            status: appointment.payload.status
          })
        }
        const allActiveAppointments = allAppointments.filter(appointment => appointment.status === AppointmentStatus.Active)
        const futureAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes > -30)
        const pastAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes <= -30)
        updateAllAppointments(allActiveAppointments)
        updateAllFutureAppointments(futureAppointments)
        updateMostRecentFutureAppointment(futureAppointments[0])
        updateAllPastAppointments(pastAppointments)
        const expertFutureConsults = futureAppointments.filter(consult => consult.expert?.email === "elvira.pistolesi@holifya.com")
        const nutritionistFutureConsults = futureAppointments.filter(consult => consult.expert?.email !== "elvira.pistolesi@holifya.com")
        nutritionistFutureConsults.length > 0 ? updateNutritionistFutureConsults(nutritionistFutureConsults) : updateNutritionistFutureConsults(null)
        expertFutureConsults.length > 0 ? updateSpecialistFutureConsults(expertFutureConsults) : updateSpecialistFutureConsults(null)
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        setIsLoading(false)
      },
    )
  }

  const getSpecialistConsultsLeft = () => {
    if (!userId || !idToken) {
      return
    }

    readsConsultsLeft(
      userId,
      idToken,
      ConsultType.Specialist,
      (number) => {
        updateSpecialistConsultsLeft(number)
      },
      (error) => {
        updateSpecialistConsultsLeft(0)
      },
    )
  }

  const getNutriConsultsLeft = () => {
    if (!userId || !idToken) {
      return
    }

    readsConsultsLeft(
      userId,
      idToken,
      ConsultType.Nutritionist,
      (number) => {
        updateNutritionistConsultsLeft(number)
      },
      () => {
        updateNutritionistConsultsLeft(0)
      },
    )
  }

  const onAppointmentsFolderClick = () => {
    navigate("your-appointments")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      "your_appointments_folder_clicked",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "equipe_page",
    )
  }

  const onExpertsFolderClick = () => {
    navigate("your-experts")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      "your_experts_folder_clicked",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "equipe_page",
    )
  }

  useEffect(() => {
    callGetAppointments()
    getNutriConsultsLeft()
    getSpecialistConsultsLeft()
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "equipe_page",
    )
  }, [])

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className="w-full h-full md:flex md:flex-row-reverse">
        <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide ${isLoading && "opacity-50 pointer-events-none"} md:w-[calc(100%-100px)] md:h-full`}>
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Equipe Medica
            </div>
            {
              (mostRecentAppointment !== null && mostRecentAppointment !== undefined) &&
              <div>
                <div className="font-bold text-[12px] leading-[20px]">
                  Prossimo appuntamento
                </div>
                <ConsultCard
                  appointment={mostRecentAppointment}
                />
              </div>
            }
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="w-full mb-[20px] md:w-[49%] md:mb-[0]">
                <FolderBox
                  folder={Folder.Appointments}
                  onClick={onAppointmentsFolderClick}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <FolderBox
                  folder={Folder.Experts}
                  onClick={onExpertsFolderClick}
                />
              </div>
            </div>
            <div className="p-[10px]" />
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
      {
        isLoading && <div className="w-full h-full absolute"><LoadingSpinner /></div>
      }
    </div>
  )
}

export default Equipe

interface Props {
    text?: string,
    onClick: () => void,
    color?: string,
}

const CloseButton: React.FC<Props> = ({
    text,
    onClick,
    color = "holifya-blue",
}) => {
    const image = color === "black" ? "/images/new-platform/components/x-black.svg" : "/images/new-platform/components/x-blue.svg"
    return (
        <div className="flex items-center cursor-pointer" onClick={onClick}>
            <div className={`font-semibold text-[12px] leading-[17px] text-${color} mr-[10px]`}>{text ? text : "Chiudi"}</div>
            <div className="leading-none"><img src={image} /></div>
        </div>
    )
}

export default CloseButton

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveEvent } from "../../../../utils/apis/user-service";
import { HOLIFYA_LOGO } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { EventType } from "../../../../utils/enums/EventType";
import { QuestionCategory } from "../../../../utils/enums/Surveys";
import { fromQuestionCategoryToString } from "../../../../utils/Functions";
import BackButton from "../../components/BackButton";
import NavBar from "../../components/NavBar";
import { NavBarElement } from "../../components/NavBarElement";

const HealthAssessment = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem(prev =>
            prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica
        );
    };

    useEffect(() => {
        if (!idToken || !email) return
        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_assessment_page",
        )
    })

    const onFolderClick = (category: QuestionCategory) => {
        navigate(`category/${category}`)
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            `${category}_folder_clicked`,
            (success) => { console.log(success) },
            (error) =>{ console.log(error) },
            "health_assessment_page",
        )
    }

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full md:flex md:flex-row-reverse">
                <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full`}>
                    <div className={`w-[90%] h-[95%] flex flex-col`}>
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Test e referti"
                                onClick={() => navigate("/diagnostica/tests-and-reports")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Health Assessment
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <HAFolder
                                category={QuestionCategory.PersonalGoals}
                                onClick={() => onFolderClick(QuestionCategory.PersonalGoals)}
                            />
                            <HAFolder
                                category={QuestionCategory.FamilyHistoryDiseases}
                                onClick={() => onFolderClick(QuestionCategory.FamilyHistoryDiseases)}
                            />
                            <HAFolder
                                category={QuestionCategory.LifestyleSports}
                                onClick={() => onFolderClick(QuestionCategory.LifestyleSports)}
                            />
                            <HAFolder
                                category={QuestionCategory.DietaryHabits}
                                onClick={() => onFolderClick(QuestionCategory.DietaryHabits)}
                            />
                            <HAFolder
                                category={QuestionCategory.Notes}
                                onClick={() => onFolderClick(QuestionCategory.Notes)}
                            />
                        </div>
                        <div className="p-[10px]" />
                    </div>
                    <NavBar
                        currentItem={currentItem}
                        onMenuClick={onMenuClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default HealthAssessment

interface HAFolderProps {
    category: QuestionCategory,
    onClick: () => void,
}

const HAFolder: React.FC<HAFolderProps> = ({
    category,
    onClick,
}) => {
    return (
        <div className="w-[calc(100%-4px)] h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer" onClick={onClick}>
            <div className="w-[90%]">
                <div className="font-bold text-[20px] leading-[33px]">{fromQuestionCategoryToString(category)}</div>
            </div>
        </div>
    )
}

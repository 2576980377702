import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../utils/api-objects/Exam"
import { getAllHealthPlanExamsByType } from "../../../utils/apis/emr-service"
import { getHealthPlan } from "../../../utils/apis/plan-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useHealthPlan } from "../../../utils/context/HealthPlanContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../utils/enums/BucketFolder"
import { EventType } from "../../../utils/enums/EventType"
import { Folder } from "./../components/Folder"
import FolderBox from "./../components/FolderBox"
import LoadingSpinner from "./../components/LoadingSpinner"
import NavBar from "./../components/NavBar"
import { NavBarElement } from "./../components/NavBarElement"
import { PdfCard, PdfType } from "./../components/PdfCard"

const HealthPlan = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { healthPlan, updateHealthPlan } = useHealthPlan()
    const hPlan = healthPlan.healthPlan
    const topHeroFood = hPlan?.data.top_hero_foods
    const topIntegratori = hPlan?.data.top_integratori
    const nutriRecommendations = hPlan?.data.nutrition_recommendations
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.HealthPlan)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.HealthPlan ? NavBarElement.Menu : NavBarElement.HealthPlan)
    }

    const [report, setReport] = useState<Exam>()
    const [isLoading, setIsLoading] = useState(true)

    const onMealPlanClicked = () => {
        navigate("meal-plan")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.MealPlanFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_plan_page",
          )
    }

    useEffect(() => {
        if (!userId || !idToken) {
            return
        }
        getAllHealthPlanExamsByType(
            userId,
            idToken,
            BucketFolder.HealthPlan,
            (exams) => {
                setReport(exams[0])
                setIsLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLoading(false)
            }
        );

        getHealthPlan(
            userId,
            idToken,
            (healthPlan) => {
                updateHealthPlan(healthPlan)
            },
            () => {
                updateHealthPlan(null)
            }
        )

        if (!email) return

        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_plan_page"
          )
    }, []);

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className={`w-full h-full ${isLoading && "opacity-50 pointer-events-none"} md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Health Plan
                        </div>
                        {
                            report ?
                                <div>
                                    <PdfCard
                                        exam={report}
                                        pdfType={PdfType.HealthPlan}
                                    />
                                    <div className="p-[10px]" />
                                    <div className="flex flex-col md:flex-row md:justify-between">
                                        {
                                            nutriRecommendations && nutriRecommendations.length > 0 && <HealthPlanCard inputs={nutriRecommendations} type={HPCategory.NutriRecommendations} />
                                        }
                                        {
                                            topHeroFood && topHeroFood.length > 0 && <HealthPlanCard inputs={topHeroFood} type={HPCategory.Food} />
                                        }
                                        {
                                            topIntegratori && topIntegratori.length > 0 && <HealthPlanCard inputs={topIntegratori} type={HPCategory.Integratori} />
                                        }
                                    </div>
                                </div> :
                                <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                                    Il tuo health plan sarà disponibile dopo il tuo consulto con nutrizionista.
                                </div>
                        }
                        <div className="w-full md:w-[50%]">
                            <FolderBox
                                folder={Folder.MealPlan}
                                onClick={onMealPlanClicked}
                            />
                        </div>
                        <div className="p-[10px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default HealthPlan

export enum HPCategory {
    Food = "hero-food",
    NutriRecommendations = "nutri-recommendations",
    Integratori = "integratori",
}

interface Props {
    inputs: string[],
    type: HPCategory,
}

export const HealthPlanCard: React.FC<Props> = ({
    inputs,
    type,
}) => {
    const handleTitle = () => {
        switch (type) {
            case HPCategory.NutriRecommendations:
                return "👩‍ Top nutrition recommendation per te"
            case HPCategory.Food:
                return `🥑 Top ${inputs.length} hero foods per te`
            case HPCategory.Integratori:
                return "💊 Top integratori per te"
        }
    }

    // ********************************************************************************

    return (
        <div className="rounded-[15px] shadow-platform px-[20px] py-[10px] mb-[20px] md:w-[32%]">
            <div className="font-bold text-[12px] leading-[33px]">{handleTitle()}</div>
            {
                inputs.map((input, index) => (
                    <div className="ml-[20px] mt-[10px]" key={index}>
                        {input}
                    </div>
                ))
            }
        </div>
    )
}

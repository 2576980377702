import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import { saveEvent } from "../../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import CloseButton from "../../components/CloseButton"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"

const Packing = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const onConfirmClick = () => {
        if (!email || !idToken) return
        navigate("/diagnostica/tests-and-reports/dna-test/request-pickup")
        saveEvent(
            email,
            idToken,
            EventType.DnaPackingButtonClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_packing_page",
        )
    }
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        if (currentItem === NavBarElement.Diagnostica) {
            setCurrentItem(NavBarElement.Menu)
        } else {
            setCurrentItem(NavBarElement.Diagnostica)
        }
    }

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_packing_page",
          )
    }, [])

    return (
        <div className="NewApp">
            <div className={`w-full h-full md:flex md:flex-row-reverse`}>
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col flex-shrink-0">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="w-full my-[10px] flex justify-end flex-shrink-0">
                            <CloseButton
                                onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px] flex-shrink-0">
                            Impacchetta
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                            Ora non ti resta che impacchettare il tuo test. <br></br>
                            Compila con data e orario di prelievo i due sticker codice campione.
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                            Incolla uno dei due sticker codice campione sulla tua Member's Card (questa card non deve essere spedita assieme al campione, ma rimarrà a te, come controprova del tuo codice campione).
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                            Procedi incollando il secondo sticker codice campione sul lato della provetta.
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                            Inserisci la provetta nella busta rinforzata / busta Biohazard e chiudila.
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                            Inserisci la busta rinforzata / busta Biohazard a sua volta nella busta del corriere e chiudi.
                        </div>
                        <div className="w-full h-[50px] mt-[20px] flex-shrink-0 md:fixed md:bottom-0 md:w-[362px] md:mb-[30px]">
                            <PrimaryButton
                                text="Fatto! Avanti"
                                pointer={true}
                                disabled={false}
                                fontSize={16}
                                onClick={onConfirmClick}
                            />
                        </div>
                        <div className="h-[20px] flex-shrink-0" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default Packing

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { BloodTestTrackingStatus } from '../enums/TrackingStatus';

interface BloodTestTrackerState {
    bloodStatus: BloodTestTrackingStatus | null;
}

// Define the shape of the blood test tracker context
interface BloodTestTrackerContextType {
    bloodTestTracker: BloodTestTrackerState;
    updateBloodTestTracker: (bloodStatus: BloodTestTrackingStatus) => void;
    clearBloodTestTracker: () => void;
}

// Create the blood test tracker context with default values
const BloodTestTrackerContext = createContext<BloodTestTrackerContextType | undefined>(undefined);

// Create a provider component
export const BloodTestTrackerProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [bloodTestTracker, setBloodTestTracker] = useState<BloodTestTrackerState>(() => {
        const savedStatus = localStorage.getItem('bloodStatus') as BloodTestTrackingStatus;
        return {
            bloodStatus: savedStatus,
        };
    });

    // Effect to update localStorage when blood test tracker state changes
    useEffect(() => {
        if (bloodTestTracker.bloodStatus !== null && bloodTestTracker.bloodStatus !== undefined) {
            localStorage.setItem('bloodStatus', bloodTestTracker.bloodStatus);
        } else {
            localStorage.removeItem('bloodStatus');
        }

    }, [bloodTestTracker.bloodStatus]);

    // Function to update blood test tracker
    const updateBloodTestTracker = (bloodStatus: BloodTestTrackingStatus) => {
        setBloodTestTracker(prevState => ({ ...prevState, bloodStatus }));
    };

    // Function to clear blood test tracker
    const clearBloodTestTracker = () => {
        setBloodTestTracker({
            bloodStatus: null,
        });
        localStorage.removeItem('bloodStatus');
    };

    // Provide context value to children
    return (
        <BloodTestTrackerContext.Provider value={{ bloodTestTracker, updateBloodTestTracker, clearBloodTestTracker }}>
            {children}
        </BloodTestTrackerContext.Provider>
    );
};

// Custom hook to use the blood test tracker context
export const useBloodTestTracker = (): BloodTestTrackerContextType => {
    const context = useContext(BloodTestTrackerContext);
    if (context === undefined) {
        throw new Error('useBloodTestTracker must be used within a BloodTestTrackerProvider');
    }
    return context;
};

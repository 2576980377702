import { User } from "../api-objects/User";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GenericSuccessCallback, GetUserDataSuccessCallback, UpdateUserDataSuccessCallback } from "./callback-helpers";

/**
 * Fetches user information based on the provided user ID and ID token.
 * 
 * @param userId - The unique identifier of the user whose information is to be retrieved.
 * @param idToken - The ID token used for authorization in the API request.
 * @param onSuccess - Callback function to be called if the user information is successfully retrieved.
 * @param onError - Callback function to be called if an error occurs during the retrieval.
 * 
 * @returns A promise that resolves when the user data is successfully fetched and updated.
 */
export async function getUserInfoApi(
    userId: string,
    idToken: string,
    onSuccess: GetUserDataSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const user = await response.json()
            onSuccess(user)
        } else {
            if (response.status === 404) {
                onError("Utente non trovato")
            } else {
                onError("Errore nel recupero dei dati dell'utente")
            }
        }
    } catch (error) {
        console.error("Get user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function updateUserInfoWithSwabcode(
    userId: string,
    idToken: string,
    swabcode: string,
    onSuccess: UpdateUserDataSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify
                ({
                    swabcode,
                })
        })
        if (response.ok) {
            const user: User = await response.json()
            onSuccess(user)
        } else {
            onError("Codice non valido")
        }
    } catch (error) {
        console.error("Update user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function updateUserInfoWithBloodcode(
    userId: string,
    idToken: string,
    bloodcodes: string[],
    onSuccess: UpdateUserDataSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify
                ({
                    bloodcodes,
                })
        })
        if (response.ok) {
            const user: User = await response.json()
            onSuccess(user)
        } else {
            onError("Codice non valido")
        }
    } catch (error) {
        console.error("Update user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function updateUserInfo(
    userId: string,
    idToken: string,
    username: string,
    familyName: string,
    phoneNumber: string,
    taxCode: string,
    onSuccess: UpdateUserDataSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/users/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tax_number: taxCode,
                    data: {
                        name: username,
                        family_name: familyName,
                        mobile_phone: phoneNumber,
                    }
                }
                ,)
        })
        if (response.ok) {
            const user: User = await response.json()
            onSuccess(user)
        } else {
            onError("Errore di aggiornamento dati")
        }
    } catch (error) {
        console.error("Update user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

//

export async function saveEvent(
    email: string,
    id_token: string,
    event_type: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
    source_page?: string,
    user_id?: string,
    report_received?: boolean,
    name?: string,
    family_name?: string,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/temp-stats`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    user_id,
                    email,
                    source_page,
                    event_type,
                    report_received,
                    name,
                    family_name,
                }
            ),
        })
        if (response.ok) {
            const success = await response.json()
            onSuccess(success)
        } else {
            // Attempt to parse the response body for error details
            let errorMessage = `Error: ${response.status} ${response.statusText}`;
            try {
                const errorBody = await response.json();
                errorMessage = errorBody?.detail || errorBody?.message || JSON.stringify(errorBody);
            } catch (parseError) {
                console.error("Error parsing response body:", parseError);
            }
            console.error("Create temp stat failed:", errorMessage);
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Create temp stat failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { saveEvent } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { ExpertGenderRole, ExpertRole } from "../../../../utils/enums/Experts"
import { handleDayOfMonth, handleHourAndMinutes, handleMonthString } from "../../../../utils/Functions"
import { Appointment } from "../../components/Appointment"

interface Props {
    appointment: Appointment,
}

const ConsultCard: React.FC<Props> = ({
    appointment,
}) => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const isUrgent = appointment.remainingMinutes <= 1440

    const handleTimeLeft = () => {
        if (appointment.remainingDays > 1) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Mancano {appointment.remainingDays} giorni</span>
        } else if (appointment.remainingDays === 1) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Manca 1 giorno</span>
        } else if (appointment.remainingHours > 1) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Mancano {appointment.remainingHours} ore</span>
        } else if (appointment.remainingHours === 1 && appointment.remainingMinutes > 55) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Manca 1 ora</span>
        } else if (appointment.remainingMinutes > 1) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Mancano {appointment.remainingMinutes} minuti</span>
        } else if (appointment.remainingMinutes === 1) {
            return <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>Manca 1 minuto</span>
        } else {
            return <span className="text-holifya-red">Il meeting è iniziato, connettiti</span>
        }
    }

    const onCancelAppointmentClick = () => {
        window.open(appointment.cancelUrl)
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            "cancel_appointment_click",
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "appointment_card"
        )
    }

    const onEditAppointmentClick = () => {
        window.open(appointment.editUrl)
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            "edit_appointment_click",
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "appointment_card"
        )
    }

    const onJoinAppointmentClick = () => {
        window.open(appointment.connectUrl)
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            "join_appointment_click",
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "appointment_card"
        )
    }

    // ********************************************************************************

    return (
        <div className={`w-full my-[10px] rounded-[15px] flex items-center justify-center ${isUrgent ? "border-2 border-solid border-holifya-blue" : "shadow-platform"}`}>
            <div className="w-full h-full p-[20px]">
                <div className="flex justify-between items-center">
                    <div className="font-bold text-[12px] leading-[20px]">
                        Videoconsulto | {handleTimeLeft()}
                    </div>
                    {
                        !isUrgent &&
                        <button
                            className="font-bold text-[12px] leading-[20px] cursor-pointer text-holifya-red"
                            onClick={onCancelAppointmentClick}
                        >
                            Cancella
                        </button>
                    }
                </div>
                <div className="font-normal text-[35px] leading-[35px] flex my-[10px]">
                    <div className="font-bold mr-[10px]">
                        {handleDayOfMonth(appointment.date)} {handleMonthString(appointment.date)}
                    </div>
                    <div>
                        {handleHourAndMinutes(appointment.date)}
                    </div>
                </div>
                <div className="w-full flex items-end justify-between mb-[20px]">
                    <div className="font-bold text-[12px] leading-[20px]">
                        <div>
                            {appointment.expert?.data.sex ? ExpertGenderRole.Dottssa : ExpertGenderRole.Dott}
                        </div>
                        <div className="text-[20px]">
                            {appointment.expert?.data.name} {appointment.expert?.data.family_name}
                        </div>
                    </div>
                    <div className="font-bold text-[14px] leading-[20px] text-holifya-blue">
                        {appointment.expert?.role === ExpertRole.Nutritionist ? "Nutrizionista" : "Medico"}
                    </div>
                </div>
                <div className="w-full h-[50px] mb-[10px]">
                    <PrimaryButton
                        text="Collegati"
                        pointer={true}
                        disabled={false}
                        fontSize={16}
                        onClick={onJoinAppointmentClick}
                    />
                </div>
                {
                    !isUrgent &&
                    <div className="w-full h-[40px]">
                        <WhiteButton
                            text="Modifica"
                            onClick={onEditAppointmentClick}
                            uppercase={false}
                            fontSize={16}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default ConsultCard

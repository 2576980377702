import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import LoadingSpinner from "../../components/LoadingSpinner"
import { getAppointments, readsConsultsLeft } from "../../utils/apis/booking-service"
import { getBloodTrackerByUserId, getTrackerByUserId, updateTrackerByOrderId } from "../../utils/apis/dna-test-tracker"
import { getHealthPlan } from "../../utils/apis/plan-service"
import { saveEvent } from "../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../utils/Constants"
import { useAuth } from "../../utils/context/AuthContext"
import { useBloodTestTracker } from "../../utils/context/BloodTextTrackerContext"
import { useConsults } from "../../utils/context/ConsultsContext"
import { useDNATestTracker } from "../../utils/context/DNATestTrackerContext"
import { useExpert } from "../../utils/context/ExpertContext"
import { useHealthPlan } from "../../utils/context/HealthPlanContext"
import { useTutorial } from "../../utils/context/TutorialContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { useProducts } from "../../utils/context/UserProductsContext"
import { AppointmentStatus, ConsultType } from "../../utils/enums/Consults"
import { EventType } from "../../utils/enums/EventType"
import { TrackingStatus } from "../../utils/enums/TrackingStatus"
import { TutorialType } from "../../utils/enums/TutorialType"
import { UpsellCardType } from "../../utils/enums/UpsellCard"
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../utils/Functions"
import { Appointment } from "./components/Appointment"
import ControlPanel from "./components/ControlPanel"
import NavBar from "./components/NavBar"
import { NavBarElement } from "./components/NavBarElement"
import SessionExpiredDialog from "./components/SessionExpiredDialog"
import UpsellCard from "./components/UpsellCard"
import { HealthPlanCard, HPCategory } from "./health-plan/HealthPlan"

const HomePage = () => {
  const navigate = useNavigate()
  const { userData } = useUserData()
  const username = userData.name
  const email = userData.email
  const swabcode = userData.swabcode
  const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
  const status = dnaTestTracker.status
  const orderId = dnaTestTracker.orderId
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { consultsData, updateSpecialistConsultsLeft, updateNutritionistConsultsLeft, updateAllAppointments, updateAllFutureAppointments, updateAllPastAppointments, updateMostRecentFutureAppointment, updateNutritionistFutureConsults, updateSpecialistFutureConsults } = useConsults()
  const { experts, updateNutritionist } = useExpert()
  const nutritionist = experts.nutritionist
  const specialist = experts.specialist
  const { bloodTestTracker, updateBloodTestTracker } = useBloodTestTracker()
  const bloodStatus = bloodTestTracker.bloodStatus
  const { healthPlan, updateHealthPlan } = useHealthPlan()
  const hPlan = healthPlan.healthPlan
  const topHeroFood = hPlan?.data.top_hero_foods
  const topIntegratori = hPlan?.data.top_integratori
  const nutriRecommendations = hPlan?.data.nutrition_recommendations
  const { tutorial } = useTutorial()
  const tutorialHP = tutorial.tutorial
  const { productsData } = useProducts()
  const dnatest = productsData.dnaTest
  const membership = productsData.membership

  const dnaTestUser = dnatest && !membership

  const [isLoading, setIsLoading] = useState(false)
  const [generalLoading, setGeneralLoading] = useState(true)
  const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState(false)

  const [currentItem, setCurrentItem] = useState(NavBarElement.Home)
  const onMenuClick = () => {
    setCurrentItem(prev => prev === NavBarElement.Home ? NavBarElement.Menu : NavBarElement.Home)
  }

  // Ref for the Upsell card
  const upsellCardRef = useRef<null | HTMLDivElement>(null)

  // Scroll to Upsell card
  const scrollToUpsellCard = () => {
    if (upsellCardRef.current) {
      upsellCardRef.current.scrollIntoView({ behavior: "smooth" }) // Smooth scrolling
    }
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.ScrollToUpsellCardButtonClicked,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "home_page"
    )
  }

  const updateTracker = (status: TrackingStatus, orderId: string) => {
    if (!idToken) return

    updateTrackerByOrderId(
      orderId,
      idToken,
      status,
      (tracker) => {
        setIsLoading(false)
        setGeneralLoading(false)
        const newStatus = tracker.tracking_status
        updateDNATestTracker(newStatus, orderId)
      },
      (error) => {
        setIsLoading(false)
        setGeneralLoading(false)
        console.log(error)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )
  }

  const callGetAppointments = () => {
    setIsLoading(true)
    if (!idToken || !email) return
    getAppointments(
      idToken,
      email,
      (appointments) => {
        const allAppointments: Appointment[] = []
        for (const appointment of appointments) {
          allAppointments.push({
            date: appointment.payload.scheduled_event.start_time,
            editUrl: appointment.payload.reschedule_url,
            connectUrl: appointment.payload.scheduled_event.location.join_url,
            cancelUrl: appointment.payload.cancel_url,
            remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
            remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
            remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
            expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? specialist! : nutritionist!,
            status: appointment.payload.status
          })
        }
        const allActiveAppointments = allAppointments.filter(appointment => appointment.status === AppointmentStatus.Active)
        const futureAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes > -30)
        const pastAppointments = allActiveAppointments.filter(appointment => appointment.remainingMinutes <= -30)
        updateAllAppointments(allActiveAppointments)
        updateAllFutureAppointments(futureAppointments)
        updateMostRecentFutureAppointment(futureAppointments[0])
        updateAllPastAppointments(pastAppointments)
        const expertFutureConsults = futureAppointments.filter(consult => consult.expert?.email === "elvira.pistolesi@holifya.com")
        const nutritionistFutureConsults = futureAppointments.filter(consult => consult.expert?.email !== "elvira.pistolesi@holifya.com")
        nutritionistFutureConsults.length > 0 ? updateNutritionistFutureConsults(nutritionistFutureConsults) : updateNutritionistFutureConsults(null)
        expertFutureConsults.length > 0 ? updateSpecialistFutureConsults(expertFutureConsults) : updateSpecialistFutureConsults(null)
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        setIsLoading(false)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )
  }

  useEffect(() => {
    if (!idToken || !userId) return

    (status === null || status === TrackingStatus.PickupRequested || status === TrackingStatus.DeliveredToLaboratory) && getTrackerByUserId(
      userId,
      idToken,
      (trackers) => {
        const tracker = trackers[0]
        const newStatus = tracker.tracking_status
        const orderId = tracker.order_id
        if (status !== newStatus || status === null) {
          updateTracker(newStatus, orderId)
        }
      },
      (error) => {
        console.log(error)
        setGeneralLoading(false)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )

    // bloodStatus === BloodTestTrackingStatus.DeliveredToLab && getBloodTrackerByUserId(
    getBloodTrackerByUserId(
      userId,
      idToken,
      (tracker) => {
        const newBloodStatus = tracker.tracking_status
        // if (newBloodStatus === BloodTestTrackingStatus.ReportGenerated) {
        updateBloodTestTracker(newBloodStatus)
        // }
      },
      (error) => {
        console.log(error)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )

    setGeneralLoading(false)

    callGetAppointments()

    readsConsultsLeft(
      userId,
      idToken,
      ConsultType.Nutritionist,
      (consults_left) => {
        if (consultsData.nutritionistConsultsLeft !== consults_left) {
          updateNutritionistConsultsLeft(consults_left)
        }
      },
      (error) => {
        console.log(error)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )

    readsConsultsLeft(
      userId,
      idToken,
      ConsultType.Specialist,
      (consults_left) => {
        if (consultsData.specialistConsultsLeft !== consults_left) {
          updateSpecialistConsultsLeft(consults_left)
        }
      },
      (error) => {
        console.log(error)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )

    getHealthPlan(
      userId,
      idToken,
      (healthPlan) => {
        updateHealthPlan(healthPlan)
      },
      () => {
        updateHealthPlan(null)
      },
      () => { if (showSessionExpiredDialog === false) setShowSessionExpiredDialog(true) }
    )

    if (!email) return

    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "home_page"
    )

  }, [])

  const shouldShowTutorial = tutorialHP !== null && tutorialHP.tutorial_type !== TutorialType.CONSULT && tutorialHP.confirmed === false
  const shouldShowHealthPlanHints = nutriRecommendations || topHeroFood || topIntegratori

  const onHowItWorksClick = () => {
    if (!tutorialHP) return
    switch (tutorialHP.tutorial_type) {
      case TutorialType.DNA_TEST:
        return navigate("/how-it-works-dna")
      case TutorialType.CONSULT:
        return navigate("/how-it-works-consults")
      case TutorialType.SUB_HEALTH:
        return navigate("/how-it-works-sub-health")
      case TutorialType.SUB_NUTRITION:
        return navigate("/how-it-works-sub-nutrition")
    }
  }

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className="w-full h-full md:flex md:flex-row-reverse">
        <div className={`w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide ${generalLoading && "visible_30_percent inactive"} md:w-[calc(100%-100px)] md:h-full`}>
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className={`w-full flex flex-col items-start justify-start font-bold text-[25px] leading-[33px] my-[10px] ${(shouldShowTutorial || dnaTestUser) && "border-b border-solid border-black"} md:flex-row md:justify-between md:items-center ${tutorial && "md:pb-[30px]"}`}>
              <div className={`${tutorial && "mb-[10px]"} md:mb-0`}>
                Ciao {username}! {"\u{1F60E}"}
              </div>
              {
                shouldShowTutorial && !dnaTestUser &&
                <div className="w-full h-[50px] mb-[20px] md:w-[362px] md:mb-0">
                  <PrimaryButton
                    text="Come funziona"
                    pointer={true}
                    disabled={false}
                    uppercase={false}
                    onClick={onHowItWorksClick}
                  />
                </div>
              }
              {
                dnaTestUser &&
                <div className="w-full h-[50px] mb-[20px] md:w-[362px] md:mb-0">
                  <PrimaryButton
                    text="Ottieni il massimo dai tuoi dati genetici  ️⬇️"
                    pointer={true}
                    disabled={false}
                    uppercase={false}
                    onClick={scrollToUpsellCard}
                  />
                </div>
              }
            </div>
            {
              shouldShowHealthPlanHints && <div className="flex flex-col md:flex-row md:justify-between md:mt-[20px]">
                {
                  nutriRecommendations && nutriRecommendations.length > 0 && <HealthPlanCard inputs={nutriRecommendations} type={HPCategory.NutriRecommendations} />
                }
                {
                  topHeroFood && topHeroFood.length > 0 && <HealthPlanCard inputs={topHeroFood} type={HPCategory.Food} />
                }
                {
                  topIntegratori && topIntegratori.length > 0 && <HealthPlanCard inputs={topIntegratori} type={HPCategory.Integratori} />
                }
              </div>
            }
            <ControlPanel />
            {
              dnaTestUser &&
              <div className="mt-[30px] md:w-[800px]" ref={upsellCardRef}>
                <UpsellCard
                  cardType={UpsellCardType.Card}
                  source_page="home_page"
                />
              </div>
            }
            <div className="p-[10px]" />
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
      {generalLoading && <div className="w-full h-full absolute"><LoadingSpinner /></div>}
      {
        showSessionExpiredDialog &&
        <SessionExpiredDialog
          onClick={() => navigate("/")}
        />
      }
    </div>
  )
}

export default HomePage

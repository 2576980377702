export enum UpsellCardType {
    Card = "card",
    Popup = "popup",
}

export enum UpsellCardItem {
    DnaTest = "dna-test",
    BloodTest = "blood-test",
    HealthScore = "health-score",
    Consults = "consults",
    HealthPlan = "health-plan",
}
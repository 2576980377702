import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { saveEvent } from "../../../../utils/apis/user-service"
import { NEW_PLATFORM_HOME_LOGO_BLACK } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import CloseButton from "../../components/CloseButton"

const HealthAssessmentSuccessPage = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "health_assessment_success_page"
    )
  }, [])

  const onDismissClick = () => {
    navigate("/home")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      "dismiss_button_click",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "health_assessment_success_page"
    )
  }

  const onCloseClick = () => {
    navigate("/home")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      "close_button_click",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "health_assessment_success_page"
    )
  }

  // ********************************************************************************
  return (
    <div className="NewApp">
      <div className="w-full h-[100px] flex items-center justify-center border-b border-solid border-black md:h-[200px]">
        <div className="w-[90%] h-[90%] flex items-center justify-between md:w-[80%]">
          <div className="flex items-center">
            <img className="mr-[10px]" src="/images/new-platform/logos/holifya-logo-no-text.svg" alt="holifya-logo" />
            <div className="font-bold text-[12px] leading-[17px]">Questionario | Health Assessment</div>
          </div>
          <CloseButton onClick={onDismissClick} />
        </div>
      </div>
      <div className="w-full h-[calc(100%-220px)] overflow-y-auto scrollbar-hide md:w-[80%] md:mt-[30px] md:h-fit">
        <div className="w-full h-full p-[20px] font-normal text-[16px] leading-[26px]">
          Fantastico! 🎉 <br />
          Ora che hai risposto a tutte le domande la tua Medical Equipe ha molte più informazioni su di te.
          Ricordati di rispondere al questionario di check-up, disponibile sempre in Home ( <span><img className="align-middle inline-block m-0 p-0 h-auto w-auto" src={NEW_PLATFORM_HOME_LOGO_BLACK} /></span> ),  per condividere aggiornamenti e progressi!
        </div>
      </div>
      <div className="w-full h-[120px] flex items-center justify-center">
        <div className="w-[90%] h-[50px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            fontSize={16}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

export default HealthAssessmentSuccessPage

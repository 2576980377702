import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import BackButton from "../components/BackButton"
import { Folder } from "../components/Folder"
import FolderBox from "../components/FolderBox"
import NavBar from "../components/NavBar"
import { NavBarElement } from "../components/NavBarElement"

const TestsAndReports = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
    const onMenuClick = () => {
        setCurrentItem((prev) => prev === NavBarElement.Diagnostica ? NavBarElement.Menu : NavBarElement.Diagnostica)
    }

    const onDnaTestClick = () => {
        navigate("dna-test")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.DnaTestFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }

    const onBloodTestClick = () => {
        navigate("blood-test")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.BloodTestFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }

    const onHealthAssessmentClick = () => {
        navigate("/health-assessment")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.HealthAssessmentFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }

    const onUploadedForYouClick = () => {
        navigate("uploaded-for-you")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.UploadedForYouFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }

    const onUploadedFromYouClick = () => {
        navigate("uploaded-from-you")
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.UploadedFromYouFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            idToken,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
          )
    }, [])

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full md:flex md:flex-row-reverse">
                <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
                    <div className="w-[90%] h-[95%] flex flex-col">
                        <div className="hidden md:block h-[35px] my-[10px]">
                            <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                        </div>
                        <div className="my-[10px]">
                            <BackButton
                                text="Torna a Diagnostica"
                                onClick={() => navigate("/diagnostica")}
                            />
                        </div>
                        <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                            Test e referti
                        </div>
                        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
                            In questa cartella puoi accedere a tutti i documenti condivisi con la tua Medical Equipe,
                            oltre ai risultati dei tuoi test e alle risposte alle domande che ti vengono sottoposte
                            con i questionari.
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <FolderBox
                                folder={Folder.DNATest}
                                onClick={onDnaTestClick}
                            />
                            <FolderBox
                                folder={Folder.BloodExams}
                                onClick={onBloodTestClick}
                            />
                            <FolderBox
                                folder={Folder.HealthAssessment}
                                onClick={onHealthAssessmentClick}
                            />
                            <FolderBox
                                folder={Folder.UploadedForYou}
                                onClick={onUploadedForYouClick}
                            />
                            <FolderBox
                                folder={Folder.UploadedFromYou}
                                onClick={onUploadedFromYouClick}
                            />
                        </div>
                        <div className="p-[10px]" />
                    </div>
                </div>
                <NavBar
                    currentItem={currentItem}
                    onMenuClick={onMenuClick}
                />
            </div>
        </div>
    )
}

export default TestsAndReports

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { Folder } from ".././components/Folder"
import FolderBox from ".././components/FolderBox"
import NavBar from ".././components/NavBar"
import { NavBarElement } from ".././components/NavBarElement"

const Diagnostica = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()
  const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
  const onMenuClick = () => {
    if (currentItem === NavBarElement.Diagnostica) {
      setCurrentItem(NavBarElement.Menu)
    } else {
      setCurrentItem(NavBarElement.Diagnostica)
    }
  }

  const onResultsClick = () => {
    navigate("results-and-progresses")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.ResultsAndProgressesFolderClicked,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "diagnostica_page",
    )
  }

  const onTestsAndDocsClick = () => {
    navigate("tests-and-reports")
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "diagnostica_page",
    )
  }

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "diagnostica_page",
    )
  }, [])

  // ********************************************************************************

  return (
    <div className="NewApp">
      <div className="w-full h-full md:flex md:flex-row-reverse">
        <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end md:w-[calc(100%-100px)] md:h-full">
          <div className="w-[90%] h-[95%] flex flex-col">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
              Diagnostica
            </div>
            <div className="flex flex-col md:flex-row md:justify-between">
              <div className="w-full mb-[20px] md:w-[49%] md:mb-[0]">
                <FolderBox
                  folder={Folder.Results}
                  onClick={onResultsClick}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <FolderBox
                  folder={Folder.TestsDocs}
                  onClick={onTestsAndDocsClick}
                />
              </div>
            </div>
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
    </div>
  )
}

export default Diagnostica

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { TrackingStatus } from '../enums/TrackingStatus';

interface DNATestTrackerState {
    status: TrackingStatus | null;
    orderId: string | null;
}

// Define the shape of the dna test tracker context
interface DNATestTrackerContextType {
    dnaTestTracker: DNATestTrackerState;
    updateDNATestTracker: (status: TrackingStatus, orderId: string) => void;
    clearDNATestTracker: () => void;
}

// Create the dna test tracker context with default values
const DNATestTrackerContext = createContext<DNATestTrackerContextType | undefined>(undefined);

// Create a provider component
export const DNATestTrackerProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [dnaTestTracker, setDNATestTracker] = useState<DNATestTrackerState>(() => {
        const savedStatus = localStorage.getItem('status') as TrackingStatus;
        // const parsedStatus = savedStatus ? (TrackingStatus[savedStatus as keyof typeof TrackingStatus]) : null;
        const orderId = localStorage.getItem('orderId');
        return {
            status: savedStatus,
            orderId,
        };
    });

    // Effect to update localStorage when dna test tracker state changes
    useEffect(() => {
        if (dnaTestTracker.status) {
            localStorage.setItem('status', dnaTestTracker.status);
        } else {
            localStorage.removeItem('status');
        }

        if (dnaTestTracker.orderId) {
            localStorage.setItem('orderId', dnaTestTracker.orderId);
        } else {
            localStorage.removeItem('orderId');
        }
    }, [dnaTestTracker.status, dnaTestTracker.orderId]);

    // Function to update dna test tracker
    const updateDNATestTracker = (status: TrackingStatus, orderId: string) => {
        setDNATestTracker(prevState => ({ ...prevState, status, orderId }));
    };

    // Function to clear dna test tracker
    const clearDNATestTracker = () => {
        setDNATestTracker({ status: null, orderId: null });
        localStorage.removeItem('status');
        localStorage.removeItem('orderId');
    };

    // Provide context value to children
    return (
        <DNATestTrackerContext.Provider value={{ dnaTestTracker, updateDNATestTracker, clearDNATestTracker }}>
            {children}
        </DNATestTrackerContext.Provider>
    );
};

// Custom hook to use the dna test tracker context
export const useDNATestTracker = (): DNATestTrackerContextType => {
    const context = useContext(DNATestTrackerContext);
    if (context === undefined) {
        throw new Error('useDNATestTracker must be used within a DNATestTrackerProvider');
    }
    return context;
};

interface Props {
    onClick?: () => void;
    showBorder?: boolean;
}

const BackButton: React.FC<Props> = ({
    onClick,
    showBorder,
}) => {
    return (
        <button
            className={`flex items-center justify-around uppercase text-[12px] font-bold w-[109px] rounded-[5px] cursor-pointer h-[55px] ${showBorder ? "border border-solid border-black" : "border-none"} `}
            onClick={onClick}
            type="button"
        >
            <img src="/images/back-arrow.svg" />
            <div className="no-underline text-black">
                Indietro
            </div>
        </button>
    )
}

export default BackButton

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import TntButton from "../../../../components/buttons/TntButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { updateTrackerByOrderId } from "../../../../utils/apis/dna-test-tracker"
import { saveEvent } from "../../../../utils/apis/user-service"
import { HOLIFYA_LOGO } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../../utils/context/DNATestTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import { PickupChoice } from "../../../../utils/enums/PickupChoice"
import { TrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"
import NavBar from "../../components/NavBar"
import { NavBarElement } from "../../components/NavBarElement"

const RequestPickup = () => {
  const { userData } = useUserData()
  const email = userData.email
  const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
  const orderId = dnaTestTracker.orderId
  const status = dnaTestTracker.status
  const { auth } = useAuth()
  const idToken = auth.idToken
  const navigate = useNavigate()

  const shouldUpdateTracker = idToken !== null && orderId !== null && status === TrackingStatus.SampleRegistered

  const onBackClick = () => {
    navigate("/diagnostica/tests-and-reports/dna-test/packing")
  }

  const [radioChoice, setRadioChoice] = useState<PickupChoice>()

  const [currentItem, setCurrentItem] = useState(NavBarElement.Diagnostica)
  const onMenuClick = () => {
    if (currentItem === NavBarElement.Diagnostica) {
      setCurrentItem(NavBarElement.Menu)
    } else {
      setCurrentItem(NavBarElement.Diagnostica)
    }
  }

  const isConfirmButtonEnabled = radioChoice !== undefined

  const warningDiv = useRef<null | HTMLDivElement>(null);

  const handleConfirmClick = () => {
    switch (radioChoice) {
      case PickupChoice.AtHome:
        window.open("https://www.tnt.it/booking/Booking.do", "_blank", "noreferrer")
        break;
      case PickupChoice.TNTPoint:
        window.open("https://www.tnt.it/contents/trova-fedex.html", "_blank", "noreferrer")
        break;
    }
    if (shouldUpdateTracker) {
      updateTrackerByOrderId(
        orderId,
        idToken,
        TrackingStatus.PickupRequested,
        (tracker) => {
          const newStatus = tracker.tracking_status
          updateDNATestTracker(newStatus, orderId)
          navigate("/diagnostica/tests-and-reports/dna-test")
        },
        (error) => {
          console.log(error)
        },
      )
    } else {
      navigate("/diagnostica/tests-and-reports/dna-test")
    }
    if (!email || !idToken) return
    saveEvent(
      email,
      idToken,
      EventType.DnaRequestPickupButtonClicked,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "dna_request_pickup_page",
    )
  }

  const onRadioCardClick = (choice: PickupChoice) => {
    setRadioChoice(choice)
    setTimeout(() => {
      warningDiv.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
        email,
        idToken,
        EventType.PageView,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        "dna_request_pickup_page",
      )
}, [])

  return (
    <div className="NewApp">
      <div className={`w-full h-full md:flex md:flex-row-reverse`}>
        <div className="w-full h-[calc(100%-90px)] flex flex-col items-center justify-end overflow-y-auto scrollbar-hide md:w-[calc(100%-100px)] md:h-full">
          <div className="w-[90%] h-[95%] flex flex-col flex-shrink-0">
            <div className="hidden md:block h-[35px] my-[10px]">
              <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
            </div>
            <div className="w-full my-[10px] flex justify-end flex-shrink-0">
              <CloseButton
                onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
              />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px] flex-shrink-0">
              Richiedi il ritiro
            </div>
            <div className="md:w-[832px]">
              <div className="mt-[10px] mb-[20px] flex-shrink-0">
                <div className="font-bold text-[16px] leading-[26px]">Inserisci la lettera di vettura nella tasca trasparente posizionata sul lato della busta del corriere.</div>
                <div className="font-normal text-[16px] leading-[26px]">Ora tutto è pronto! Ti basta selezionare uno dei due metodi di ritiro qui sotto.</div>
              </div>
              <div className="mt-[10px] mb-[20px] flex-shrink-0">
                <div className="font-bold text-[16px] leading-[26px]">Ritiro a domicilio</div>
                <div className="font-normal text-[16px] leading-[26px]">
                  Se qui sotto selezioni “Ritiro a domicilio” verrai reindirizzato sul sito di FedEx TNT per inserire i tuoi dati di ritiro:
                  <ul className="list-disc ml-[20px]">
                    <li className="my-[5px] ml-[20px]">seleziona l'opzione “Mittente convenzionato” e lascia la spunta su “Merce destinata in Italia” e prosegui;</li>
                    <li className="my-[5px] ml-[20px]">inserisci i tuoi dati relativi a dove ritirare il tuo campione e prosegui;</li>
                    <li className="my-[5px] ml-[20px]">inserisci le tue preferenze su quando ritirare il tuo campione e prosegui;</li>
                    <li className="my-[5px] ml-[20px]">inserisci la tua mail e prosegui;</li>
                    <li className="my-[5px] ml-[20px]">inserisci le dimensioni e il peso (c.ca 0,5 kg e 15cm x 15cm x 5cm) e prosegui;</li>
                    <li className="my-[5px] ml-[20px]">controlla e conferma.</li>
                  </ul>
                  <div className="font-normal text-[16px] leading-[26px]">Il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, verrà ritirato secondo le istruzioni da te fornite e consegnato in pochi giorni al nostro laboratorio.</div>
                </div>
              </div>
              <div className="mt-[10px] mb-[20px] flex-shrink-0">
                <div className="font-bold text-[16px] leading-[26px]">Consegna in FedEx TNT Point</div>
                <div className="font-normal text-[16px] leading-[26px]">
                  Se qui sotto selezioni “Consegna in FedEx TNT Point”, dopo la conferma verrai reindirizzato al sito di FedEx TNT per trovare il FedEx TNT Point più vicino a te.
                  Porta il pacchetto contenente il tuo test del DNA, con la lettera di vettura allegata, al FedEx TNT Point che preferisci. La lettera di vettura allegata permetterà al tuo campione di arrivare in pochi giorni al nostro laboratorio.
                </div>
              </div>
              <div className="mt-[10px] mb-[20px] flex-shrink-0">
                <div className="font-bold text-[16px] leading-[26px] italic">Cos'è una lettera di vettura?</div>
                <div className="font-normal text-[16px] leading-[26px] italic">
                  <div>La lettera di vettura è un foglio allegato ad ogni Kit Holifya, che troverete all'interno del pacchetto di spedizione.</div>
                  <div>La lettera di vettura è essenziale per rispedire il campione al nostro laboratorio e andrà consegnata assieme alla busta TNT.</div>
                </div>
              </div>
              <div className="w-full h-[180px] flex flex-col justify-between mt-[10px] mb-[20px] flex-shrink-0 md:flex-row md:h-fit md:my-[50px] md:w-[90%]">
                <RadioCard
                  choice={PickupChoice.AtHome}
                  isChecked={radioChoice === PickupChoice.AtHome}
                  onClick={onRadioCardClick}
                />
                <RadioCard
                  choice={PickupChoice.TNTPoint}
                  isChecked={radioChoice === PickupChoice.TNTPoint}
                  onClick={onRadioCardClick}
                />
              </div>
              {
                radioChoice && <div ref={warningDiv}><PickupWarningBox choice={radioChoice}></PickupWarningBox></div>
              }
              <div className="w-full h-[100px] flex flex-col justify-between mt-[20px] flex-shrink-0 md:flex-row md:h-[50px] md:w-[90%]">
                <div className="w-full h-[50px] md:w-[362px]">
                  <TntButton
                    text="Conferma"
                    pointer={isConfirmButtonEnabled}
                    disabled={!isConfirmButtonEnabled}
                    fontSize={16}
                    uppercase={true}
                    onClick={handleConfirmClick}
                  />
                </div>
                <div className="w-full h-[40px] md:w-[362px]">
                  <WhiteButton
                    text="Indietro"
                    onClick={onBackClick}
                    fontSize={16}
                    uppercase={false}
                  />
                </div>
              </div>
              <div className="h-[20px] flex-shrink-0" />
            </div>
          </div>
        </div>
        <NavBar
          currentItem={currentItem}
          onMenuClick={onMenuClick}
        />
      </div>
    </div>
  )
}

export default RequestPickup

interface PickupWarningBoxProps {
  choice: PickupChoice,
}

export const PickupWarningBox: React.FC<PickupWarningBoxProps> = ({
  choice,
}) => {
  return (
    <div className="w-full flex items-center rounded-[15px] bg-holifya-yellow">
      <div className="flex flex-col p-[20px]">
        <div className="flex items-center mb-[20px] pickup-warning-box-heading">
          <div className="font-bold text-[20px] leading-[26px]">⚠️ Attenzione</div>
        </div>
        <div className="font-normal text-[16px] leading-[26px]">
          {
            choice === PickupChoice.AtHome ?
              <div>
                Non verrai addebitato di alcun importo per il ritiro del campione.<br></br> È importante che venga selezionata l'opzione <strong>"Mittente convenzionato"</strong> e che venga lasciata la spunta su <strong>"Merce destinata in Italia"</strong>.
              </div>
              :
              <div>
                <strong>La lettera di vettura</strong> in tuo possesso è valida anche per far recuperare il tuo campione in un Fedex TNT Point. <strong>Ricordati di allegarla alla busta contenente il campione</strong> prima di consegnarla al al Fedex TNT Point più comodo per te. Confermando sarai reindirizzato sulla pagina del fornitore di logistica per trovare il punto di raccolta più vicino.
              </div>
          }
        </div>
      </div>
    </div>
  )
}

interface Props {
  choice: PickupChoice,
  isChecked: boolean,
  onClick: (choice: PickupChoice) => void,
}

export const RadioCard: React.FC<Props> = ({
  choice,
  isChecked,
  onClick,
}) => {
  return (
    <div
      className={`cursor-pointer w-full h-[80px] rounded-[5px] flex items-center justify-center ${isChecked ? "border border-solid border-black" : "shadow-platform"} md:w-[362px]`}
      onClick={() => onClick(choice)}
    >
      <div className="w-[90%] flex items-center">
        <div className="w-[20px] h-[20px] border border-solid border-black rounded-[10px] flex items-center justify-center mr-[10px]">
          {isChecked && <div className="w-[12px] h-[12px] rounded-[6px] bg-black"></div>}
        </div>
        <div className="font-bold text-[15px] leading-[21px]">{choice}</div>
      </div>
    </div>
  )
}
